import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import cvData from './data.json'
import './Portfolio.css'; // Importing custom CSS
import './RadialProgress.css';
import MatrixBackground from './MatrixBackground'; // Importing the MatrixBackground component
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useInView } from 'react-intersection-observer';
import Education from './Education';

//robpayton1@gmail.com

// Portfolio component
const Portfolio = () => {
  // State for selected role, default is 'All'
  const [selectedRole, setSelectedRole] = useState('All');
  const [activeSection, setActiveSection] = useState('bio');

  // Refs for each section
  const bioRef = useRef(null);
  const skillsRef = useRef(null);
  const experienceRef = useRef(null);
  const projectsRef = useRef(null);
  const educationRef = useRef(null);
  const interestsRef = useRef(null);

  // Function to handle role change
  const handleRoleChange = (role) => {
    setSelectedRole(role);
  };

  // Function to scroll to a section
  const scrollToSection = (ref) => {
    // ref.current.scrollTo( 0, 1000);

    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start'});
  };

  // Function to handle scroll event
  const handleScroll = () => {
    const bioTop = bioRef.current.getBoundingClientRect().top;
    const skillsTop = skillsRef.current.getBoundingClientRect().top;
    const experienceTop = experienceRef.current.getBoundingClientRect().top;
    const projectsTop = projectsRef.current.getBoundingClientRect().top;
    const educationTop = educationRef.current.getBoundingClientRect().top;
    const interestsTop = interestsRef.current.getBoundingClientRect().top;

    if (bioTop <= 0 && skillsTop > 100) {
      setActiveSection('bio');
    } else if (skillsTop <= 100 && experienceTop > 100) {
      setActiveSection('skills');
    } else if (experienceTop <= 100 && projectsTop > 100) {
      setActiveSection('experience');
    } else if (projectsTop <= 100 && educationTop > 100) {
      setActiveSection('projects');
    } else if (educationTop <= 100 && interestsTop > 100) {
      setActiveSection('education');
    } else if (interestsTop <= 100) {
      setActiveSection('interests');
    }
  };

  // Add scroll event listener on component mount and remove on unmount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    // Determine the background color and bio picture based on the selected role
  let backgroundColor, bioPicture;
  switch (selectedRole) {
    case 'Software Engineer':
      // backgroundColor = '#123456';
      bioPicture = cvData.headshotalt;
      break;
    case 'AI Consultant':
      backgroundColor = 'rgba(25,25,25,0.8)';
      bioPicture = cvData.headshotalt2;
      break;
    // Add more cases as needed...
    default:
      backgroundColor = '#333';
      bioPicture = cvData.headshot;
  }

  return (
    <div class="container portfolio-container" style={{ backgroundColor }}>
      {selectedRole === 'Software Engineer' && <MatrixBackground />}
      <div class="navbar">
        <button class={activeSection === 'bio' ? 'active' : ''} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
          Bio
        </button>
        <button class={activeSection === 'skills' ? 'active' : ''} onClick={() => scrollToSection(skillsRef)}>
          Skills
        </button>
        <button class={activeSection === 'experience' ? 'active' : ''} onClick={() => scrollToSection(experienceRef)}>
          Exp
        </button>
        <button class={activeSection === 'projects' ? 'active' : ''} onClick={() => scrollToSection(projectsRef)}>
          Projects
        </button>
        <button class={activeSection === 'education' ? 'active' : ''} onClick={() => scrollToSection(educationRef)}>
          Education
        </button>
        <button class={activeSection === 'interests' ? 'active' : ''} onClick={() => scrollToSection(interestsRef)}>
          Interests
        </button>
      </div>
      <div class="role-selector container">
        <h2>Select a role</h2>
        <div class="btn-group">
          {cvData.roles.map((role, index) => (
            <button key={index} class={`btn btn-${selectedRole === role ? 'primary' : 'secondary'}`} onClick={() => handleRoleChange(role)}>
              {role}
            </button>
          ))}
        </div>
      </div>
      <div ref={bioRef}></div>
      <BioSection bio={cvData.bio} headshot={bioPicture} />
      <div ref={skillsRef}><img class="page-title" src="./Skills2.png"></img></div>
      <SkillsSection skills={cvData.skills} selectedRole={selectedRole} />
      <div ref={experienceRef}><img class="page-title" src="./Experience2.png"></img></div>
      <ExperienceSection experiences={cvData.experience} selectedRole={selectedRole} />
      <div ref={projectsRef}><img class="page-title" src="./Projects2.png"></img></div>
      <ProjectsSection projects={cvData.projects} selectedRole={selectedRole}/>
      <div ref={educationRef}><img class="page-title" src="./Education2.png"></img></div>
      <EducationSection qualifications={cvData.education} selectedRole={selectedRole} />
      <div ref={interestsRef}></div>
      <InterestsSection interests={cvData.interests} />
    </div>
  );
};



// SkillsSection component
const SkillsSection = ({ skills, selectedRole }) => (
  <div class="skills-section">
    <div class='Title'>Skills</div>
    <div class='skills-wrapper'>
    {skills
      .filter(skill => selectedRole === 'All' || skill.roles.includes(selectedRole))
      .map((skill, index) => (
        <Skill key={index} skill={skill} />
      ))}
      </div>
  </div>
);

// Skill component
const Skill = ({ skill }) => {
  // State for expanded view, default is false
  const [isExpanded, setIsExpanded] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
  });
  // Function to handle click on skill
  const handleSkillClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div ref={ref} class='skill bg-dark' onClick={handleSkillClick}>
      
      <div class="skill-div">
        <div class='skill-name'>{skill.name}</div>
        <div class={`pie-wrapper progress-${skill.proficiency} style-2`} >
          <span class="label">{skill.proficiency}<span class="smaller">/100</span></span>
          <div class={`pie ${inView ? 'fade-rotate-animate' : ''}`}>
            <div class="left-side half-circle"></div>
            <div class="right-side half-circle"></div>
          </div>
          <div class="shadow"></div>
        
        </div>
        {isExpanded && <div class='skill-desc'>{skill.description}</div>}
      </div>

    </div>
  );
};




// BioSection component
const BioSection = ({ bio, headshot }) => (
  <div class="bio-section">
    {/* <div class='Title'>Joseph Loe</div> */}
    <img class='headshot' src={headshot} alt="Headshot" />
    <div class='pl-5'>{bio}</div>
  </div>
);

// ExperienceSection component
const ExperienceSection = ({ experiences, selectedRole }) => (
  <div class="experience-section">
    <div class='Title'>Previous Experience</div>
    {experiences
      .filter(experience => selectedRole === 'All' || experience.roles.includes(selectedRole))
      .map((experience, index) => (
        <Experience key={index} experience={experience} />
      ))}
  </div>
);

// Experience component
const Experience = ({ experience }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
  });
  return (
    // <div ref={ref} class={`card project text-white ${inView ? 'project-animate' : ''}`}></div>
  <div ref={ref} class={`card experience ${inView ? 'experience-animate' : ''}`}>
    <div class="card-header">
      <img src={experience.logo} alt={experience.company} class={`experience-logo ${inView ? 'pulse-animate' : ''}`} />
      <div class="experience-details">
        <h3>{experience.title}</h3>
        <h4>{experience.company}</h4>
        <p>{experience.duration}</p>
        {experience.links && experience.links.length > 0 ? (
        <div class="experience-links">
          {experience.links.map((link, index) => (
            <a key={index} href={link} target="_blank" rel="noopener noreferrer" class="btn btn-primary">
              {new URL(link).hostname}
            </a>
          ))}
        </div>
      ) : (
        <p>No links available</p>
      )}
      </div>
    </div>
    <div class="card-body">
      <p>{experience.description}</p>
    </div>
  </div>
)};

// ProjectsSection component
const ProjectsSection = ({ projects, selectedRole }) => (
  <div class="projects-section">
    <div class='Title'>Previous Projects</div>
    {projects
      .filter(project => selectedRole === 'All' || project.roles.includes(selectedRole))
      .map((project, index) => (
        <Project key={index} project={project} />
      ))}
  </div>
);

// Project component
// const Project = ({ project }) => (
//   <div class="Project">
//     <img src={project.photo} alt={project.name} />
//     <h3>{project.name}</h3>
//     <p>{project.duration}</p>
//     <p>{project.description}</p>
//   </div>
// );

// Project component
const Project = ({ project }) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Change this to false if you want the animation to trigger again whenever it comes in view
  });

  return (
    <div ref={ref} class={`card project text-white ${inView ? 'project-animate' : ''}`}>
    <div class="card-header">
      <div class="project-title-wrapper">
      <h3 class={`project-name display-4 ${inView ? 'typing' : ''}`}>{project.name}</h3>
      </div>
      
      {project.startDate && project.endDate && (
        <p class="project-dates">{`${project.startDate} - ${project.endDate}`}</p>
      )}
    </div>
    {project.images && project.images.length > 0 ? (
      <Carousel showThumbs={true}>
        {project.images.map((image, index) => (
          <div key={index}>
            <img class="project-carousel" src={image} alt={`${project.name}-${index + 1}`} />
          </div>
        ))}
      </Carousel>
    ) : (
      <div class="project-placeholder">No images available</div>
    )}
    <div class="card-body">
      <p class="card-text">{project.description}</p>
      <div class="project-technologies">
        {/* Add cascade fade */}
        {project.technologies.map((technology, index) => (
          <span key={index} class={`badge badge-pill badge-warning ${inView ? 'slide-up' : ''}`} style={{ animationDelay: `${index * 0.25}s` }}>{technology}</span>
        ))}
      </div>
      {project.links && project.links.length > 0 ? (
        <div class="project-links">
          {project.links.map((link, index) => (
            <a key={index} href={link} target="_blank" rel="noopener noreferrer" class="btn btn-primary">
              {new URL(link).hostname}
            </a>
          ))}
        </div>
      ) : (
        <div>No links provided</div>
      )}
    </div>
  </div>
)};

// EducationSection component
const EducationSection = ({ qualifications, selectedRole }) => (
  <div class="education-section">
    <div class='Title'>Education</div>
    {qualifications
      .filter(qualification => selectedRole === 'All' || qualification.roles.includes(selectedRole))
      .map((qualification, index) => (
        <Education key={index} education={qualification} />
      ))}
  </div>
);

// Qualification component
const Qualification = ({ qualification }) => (
  <div class="qualification">
    <h3>{qualification.title}</h3>
    <p>{qualification.description}</p>
  </div>
);

// InterestsSection component
const InterestsSection = ({ interests }) => (
  <div class="interests-section">
    <div class='Title'>Interests</div>
    {interests.map((interest, index) => (
      <Interest key={index} interest={interest} />
    ))}
  </div>
);

// Interest component
const Interest = ({ interest }) => (
  <div class="interest">
    <h3>{interest.title}</h3>
    <p>{interest.description}</p>
  </div>
);

export default Portfolio;
