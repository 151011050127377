import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useInView } from 'react-intersection-observer';

const Education = ({ education }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  return (
    <div ref={ref} className={`card education ${inView ? 'slide-animate' : ''}`}>
      <h2 className="education-title">{education.institution}</h2>
      <h3 className="education-course">{education.course}</h3>
      <p className="education-grade">Grade: {education.grade}</p>
      <Carousel showThumbs={false}>
        {education.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Education ${index}`} />
          </div>
        ))}
      </Carousel>
      <p className="education-description">{education.description}</p>
      <div className="education-modules text-white">
        {education.modules?.map((module, index) => (
          <div key={index} className={`module ${inView ? 'fade-forward' : ''}`} style={{ animationDelay: `${index * 0.25}s` }}>
            <p className="module-name">{module.name}</p>
            <p className="module-grade">{module.grade}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Education;
