import React, { useEffect } from 'react';

// MatrixBackground component
const MatrixBackground = () => {
  useEffect(() => {
    // Get the canvas and context
    const canvas = document.getElementById('matrix-canvas');
    const ctx = canvas.getContext('2d');

    // Set the canvas size
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    // Set up the letters
    const letters = 'ﾊﾐﾋｰｳｼﾅﾓﾆｻﾜﾂｵﾘｱﾎﾃﾏｹﾒｴｶｷﾑﾕﾗｾﾈｽﾀﾇﾍ012345789Z日:・."=*+-<>¦｜╌çｸ'.repeat(6).split('');
    // const letters = '01'.repeat(10).split('') + '2';
    // const letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZ'.repeat(6).split('');

    // Set up the columns
    const fontSize = 24;
    const columns = canvas.width / fontSize;
    ctx.font = "24px Titillium Web regular, sans-serif";
    // Set up the drops
    const drops = Array(parseInt(columns) * 2).fill(Math.random() * canvas.height * 2);

    // Set up the draw function
    function draw() {
      ctx.fillStyle = 'rgba(0, 0, 0, .05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < drops.length; i++) {
        const text = letters[Math.floor(Math.random() * letters.length)];
        const lol = Math.random();
        const lol2 = lol > 0.5;
        ctx.fillStyle = lol2 ? 'rgba(218,165,32, 1)' : 'rgba(160, 10, 10, 1)';
        ctx.fillText(text, i * fontSize / 2, drops[i] * fontSize / 2);
        drops[i] += Math.random() * 2.0;
        // drops[i]++;
        if (drops[i] * fontSize / 2 > canvas.height && Math.random() > .99) {
          drops[i] = 0;
        }
      }
    }

    const prevDrops = Array(parseInt(columns) * 2).fill(null);

    function draw2() {
      ctx.fillStyle = 'rgba(0, 0, 0, .05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      for (let i = 0; i < drops.length; i++) {
        // Redraw the previous drop in the appropriate color
        if (prevDrops[i] !== null) {
          const prevText = prevDrops[i].text;
          const prevX = prevDrops[i].posX;
          const prevY = prevDrops[i].posY;
          const lol = Math.random();
          const lol2 = lol > 1.5;
          ctx.font = "24px Titillium Web bold, sans-serif";
          ctx.fillStyle = lol2 ? 'rgba(218,165,32, .8)' : 'rgba(160, 5, 5, 1.8)';
          // ctx.fillStyle = lol2 ? 'rgba(218,165,32, .8)' : 'rgba(218,165,32, .8)';
          ctx.fillText(prevText, prevX, prevY);
        }

        drops[i] += Math.random();
        if (drops[i] * fontSize / 2 > canvas.height && Math.random() > .99) {
          drops[i] = 0;
          prevDrops[i] = null;  // Reset the previous drop when we reset the current drop
        }
    
        // Draw a white drop at the current position
        const text = letters[Math.floor(Math.random() * letters.length)];

        ctx.font = "24px Titillium Web regular, sans-serif";
        // ctx.fillStyle = 'rgba(255,255,255,0.5)';
        ctx.fillStyle = 'rgba(218,165,32, 1.0)';
        const posX = i * fontSize / 2;
        const posY = drops[i] * fontSize;
        ctx.fillText(text, posX, posY);
    
        // Store the current drop for the next frame
        prevDrops[i] = { text, posX, posY };
    
        
      }
    }
    // Start the animation
    const intervalId = setInterval(draw2, 33);

    // Clean up the animation when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  return <canvas id="matrix-canvas" class="matrix-background" />;
};

export default MatrixBackground;